import { signup, socialSignup } from '@api/authentication';
import { TIdentity } from '@components/types';
import { useGoogleLogin } from '@react-oauth/google';
import { Input } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

interface Props {
  type: string;
  setIsSignUp?: Function;
}

export const SignUp: React.FC<Props> = ({ type, setIsSignUp }: Props) => {
  const [message, setMessage] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [state, setState] = useState<TIdentity>();
  const router = useRouter();

  const handleInput = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [evt.target.name]: evt.target.value });
    if (
      ((evt.target.name == 'password1' &&
        state?.password == evt.target.value) ||
        (evt.target.name == 'password' &&
          state?.password1 == evt.target.value)) &&
      state?.password !== undefined
    ) {
      setIsPasswordMatch(true);
    } else {
      setIsPasswordMatch(false);
    }
    setMessage('');
  };

  const handleSignup = async (IsSmallDevice: boolean) => {
    console.debug(state);
    let emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
    let phoneRegex = new RegExp(/^(\+91)?(0)?\d{10}$/);
    if (state?.email === undefined || state?.email.length == 0) {
      setMessage('Please provide email');
      return;
    } else if (!emailRegex.test(state?.email)) {
      setMessage('Please provide correct email');
    }

    if (state?.phone === undefined || state?.phone.length == 0) {
      setMessage('Please provide phone number');
      return;
    } else if (!phoneRegex.test(state?.phone)) {
      setMessage('Please provide correct phone number');
      return;
    }

    if (state?.password === undefined) {
      setMessage('Please provide password');
      return;
    }

    if (state?.password1 !== state?.password) {
      setMessage("Password didn't match");
      return;
    }

    if (state !== undefined) {
      const res = signup(state);
      if (res) {
        window?.fbq('track', 'CompleteRegistration');
      }
      IsSmallDevice ? handleSignupPromise(res) : handleSignupPromiseForLg(res);
    }
  };
  const handleSignupPromise = (promise: Promise<any>) => {
    promise
      .then((result) => {
        router.push('/screen/complete/signup');
      })
      .catch((result) => {
        console.debug(result);
        if (typeof result.response.data.detail == 'string') {
          setMessage(result.response.data.detail);
        } else {
          setMessage('Some error occurred.');
        }
      });
  };

  const handleSignupPromiseForLg = (promise: Promise<any>) => {
    promise
      .then((result) => {
        setIsSignUp(false);
      })
      .catch((result) => {
        console.debug(result);
        if (typeof result?.response?.data?.detail == 'string') {
          setMessage(result?.response?.data?.detail || "Some error occurred");
        } else {
          setMessage('Some error occurred.');
        }
      });
  };

  useEffect(() => {
    setState({ ...state, is_business: type.toLowerCase() === 'business' });
  }, [type]);

  const handleSocialSignup = (provider: string) => {
    const handle = async (response: any) => {
      const res = socialSignup(provider, response.access_token);
      handleSignupPromise(res);
    };
    return handle;
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) =>
      handleSocialSignup('google')(tokenResponse),
    onError: (errorResponse) => console.debug(errorResponse),
  });

  return (
    <div className="w-full flex flex-col items-center justify-center lt-sm:overflow-hidden font-manrope">
      <div className="flex flex-col gap-2 w-full">
        <div className="flex justify-center pb-8 text-xl md:text-2xl">
          <h2 className="font-semibold text-neutral-700 font-manrope">
            Sign Up
          </h2>
        </div>
        <div className="flex flex-col gap-4 px-2">
          <div className="flex items-center w-full gap-2">
            <label className="font-bold text-neutral-700 block w-full md:text-base text-sm">
              <span className="block">First Name</span>
              <Input
                type="text"
                className="border-neutral-300 rounded-lg md:rounded-r-none bg-neutral-50"
                name="first_name"
                placeholder="Enter First Name"
                value={state?.first_name}
                onChange={(evt) => handleInput(evt)}
              />
            </label>
            <label className="font-bold text-neutral-700 w-full block md:text-base text-sm">
              <span className="block">Last Name</span>
              <Input
                type="text"
                placeholder="Enter First Name"
                className="border-neutral-300 rounded-lg md:rounded-l-none bg-neutral-50"
                name="last_name"
                value={state?.last_name}
                onChange={(evt) => handleInput(evt)}
              />
            </label>
          </div>
          <div>
            <label className="font-bold text-neutral-700 block md:text-base text-sm">
              <span className="block">Email</span>
              <Input
                type="email"
                placeholder="Enter Your Email"
                className="border-neutral-300 rounded-lg bg-neutral-50"
                name="email"
                value={state?.email}
                onChange={(evt) => handleInput(evt)}
                required
              />
            </label>
          </div>
          <div>
            <label className="font-bold text-neutral-700 block md:text-base text-sm">
              <span className="block">Mobile Number</span>
              <Input
                type="tel"
                className="border-neutral-300 rounded-lg bg-neutral-50"
                name="phone"
                value={state?.phone}
                placeholder="+91999999999"
                onChange={(evt) => handleInput(evt)}
                required
              />
            </label>
          </div>
          <div>
            <label className="font-bold text-neutral-700 block md:text-base text-sm">
              <span className="block">Password *</span>
              <Input
                type="password"
                placeholder="Enter a Password"
                className="border-neutral-300 rounded-lg bg-neutral-50"
                name="password"
                value={state?.password}
                onChange={(evt) => handleInput(evt)}
                required
              />
            </label>
          </div>
          <div>
            <label className="font-bold text-neutral-700 block md:text-base text-sm">
              <span className="block">Confirm Password *</span>
              <Input
                type="password"
                placeholder="Re-enter your Password"
                className="border-neutral-300 rounded-lg bg-neutral-50"
                name="password1"
                value={state?.password1}
                onChange={(evt) => handleInput(evt)}
                required
              />
            </label>
          </div>
          <div>
            <p className="text-error-900 font-semibold text-center">
              {message}
            </p>
          </div>
          <div className="text-right hidden md:block">
            <button
              onClick={() => {
                handleSignup(false);
              }}
              className="block w-full inline-flex justify-center rounded-md border border-transparent bg-brand-500 py-1 px-4 text-base font-medium text-neutral-50 shadow-sm hover:bg-brand-900 focus:outline-none focus:ring-brand-500 focus:ring-2 focus:bg-brand-500 focus:ring-offset-2"
            >
              Create Account
            </button>
          </div>
          <div className="text-right md:hidden block">
            <button
              onClick={() => {
                handleSignup(true);
              }}
              className="block w-full inline-flex justify-center rounded-md border border-transparent bg-brand-500 py-1 px-4 text-base font-medium text-neutral-50 shadow-sm hover:bg-brand-900 focus:outline-none focus:ring-brand-500 focus:ring-2 focus:bg-brand-500 focus:ring-offset-2"
            >
              Create Account
            </button>
          </div>
          <div className="block w-full inline-flex gap-1 justify-center rounded-md">
            Already have an Account?{' '}
            <Link href="/login" prefetch={false}>
              <span className="font-semibold uppercase font-manrope text-base text-brand-500">
                Login
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
