import {
    getprofile,
    updateProfile,
    uploadProfileImage,
} from '@api/authentication';
import { verifyMobile } from '@api/verification';
import { TIdentity, TOtp } from '@components/types';
import { requestLogin, sanityIoImageLoader } from '@core/utils';
import { profileState } from '@recoil/atoms';
import { Input } from 'antd';
import { ChevronLeft, Loader, Pencil } from 'lucide-react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

interface Props {
  hideBackText?: boolean;
}
export const ProfileCard: React.FC<Props> = ({ hideBackText }) => {
  const [user, setUser] = useRecoilState<TIdentity>(profileState);
  const [imageFile, setImageFile] = useState<File>(null);
  const [otp, setOtp] = useState<TOtp>();
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const mobile = user?.phone;

  const router = useRouter();

  const handleInput = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [evt.target.name]: evt.target.value });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setImageFile(file || null);
  };

  const updateUser = async () => {
    const res: Promise<TIdentity> = updateProfile(user);
    setIsLoading(true);
    res.then((result) => setUser(result));
    res
      .then((result) => localStorage.setItem('profile', JSON.stringify(result)))
      .finally(() => {
        setIsLoading(false);
        router.back();
      });
  };

  useEffect(() => {
    const res: Promise<TIdentity> = getprofile();
    res.then((result) => setUser(result));
  }, []);

  useEffect(() => {
    setOtp({ ...otp, key: user?.phone, type: 'm' });
  }, [user]);

  const handleVerify = () => {
    if (user?.phone !== undefined) {
      const result = verifyMobile(otp);
      result
        .then((res: TOtp) => {
          router.push(res.link);
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            requestLogin(router.asPath);
          }
        });
    }
  };

  const uploadNewImage = () => {
    if (imageFile !== null && imageFile !== undefined) {
      uploadProfileImage(imageFile).then(() => {
        setImageLoading(true);
        const res: Promise<TIdentity> = getprofile();
        res.then((result) => {
          setUser(result);
          setImageLoading(false);
        });
      });
    }
  };

  useEffect(() => {
    uploadNewImage();
  }, [imageFile]);

  return (
    <section className="h-full font-manrope antialised">
      <div className={`md:pt-10 ${hideBackText ? 'hidden md:block' : 'block'}`}>
        <button
          type="button"
          onClick={() => router.back()}
          className="flex items-center gap-2 cursor-pointer"
        >
          <ChevronLeft className="w-4 h-4 text-neutral-800" />
          <span className="text-base md:text-lg text-neutral-800 font-manrope font-semibold">
            Edit Profile
          </span>
        </button>
      </div>

      <form
        onSubmit={updateUser}
        className="md:mx-auto md:w-[50%] md:mt-8 p-2 md:p-6 lg:px-8 flex flex-col gap-2.5 md:gap-4 rounded-md lg:rounded-lg bg-white"
      >
        <div className="pb-4 flex justify-center">
          <label
            htmlFor="profile_image"
            className="w-24 h-24 relative rounded-full border border-solid border-neutral-200 cursor-pointer"
          >
            <Image
              loader={sanityIoImageLoader}
              src={user?.profile_image || 'assets/esangrah-profile.png'}
              alt={user?.first_name}
              fill
              className="absolute inset-0 rounded-full aspect-square"
            />
            <label
              htmlFor="profile_image"
              className="w-6 h-6 px-0 absolute bottom-1 right-1 z-10 grid place-items-center rounded-full bg-brand-500 cursor-pointer"
            >
              {imageLoading ? (
                <Loader className="w-3 h-3 text-white animate-spin" />
              ) : (
                <Pencil className="w-3 h-3 text-white" />
              )}
            </label>
          </label>
          <input
            type="file"
            id="profile_image"
            onChange={handleFileChange}
            name="profile_image"
            className="hidden"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-2.5 md:gap-4">
          <div className="flex flex-col gap-1">
            <label
              htmlFor="first_name"
              className="text-sm text-neutral-800 font-medium"
            >
              First Name
            </label>
            <Input
              size="large"
              id="first_name"
              name="first_name"
              value={user?.first_name}
              onChange={(event) => handleInput(event)}
              placeholder="Enter first name"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label
              htmlFor="last_name"
              className="text-sm text-neutral-800 font-medium"
            >
              Last Name
            </label>
            <Input
              size="large"
              id="last_name"
              name="last_name"
              value={user?.last_name}
              onChange={(event) => handleInput(event)}
              placeholder="Enter last name"
            />
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <label
            htmlFor="email"
            className="text-sm text-neutral-800 font-medium"
          >
            Email Address
          </label>
          <Input
            size="large"
            id="email"
            name="email"
            value={user?.email}
            onChange={(event) => handleInput(event)}
            placeholder="Enter email address"
          />
        </div>

        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <label
              htmlFor="phone_number"
              className="text-sm text-neutral-800 font-medium"
            >
              Phone Number
            </label>
            <button
              type="button"
              onClick={() => handleVerify()}
              className="px-0 text-sm text-brand-500 font-manrope font-semibold underline cursor-pointer"
            >
              Verify
            </button>
          </div>
          <Input
            name="phone"
            size="large"
            id="phone_number"
            value={user?.phone}
            onChange={(event) => handleInput(event)}
            placeholder="Enter phone number"
          />
        </div>

        <div className="pt-2 md:pt-0 flex flex-wrap justify-center items-center gap-2.5">
          <button
            type="button"
            onClick={() => router.back()}
            className="md:w-40 h-10 md:h-11 px-4 transition-all duration-200 rounded-md border border-solid border-brand-500 cursor-pointer"
          >
            <span className="text-sm text-brand-500 font-manrope font-semibold">
              Cancel
            </span>
          </button>

          <button
            type="submit"
            className="md:w-40 h-10 md:h-11 px-4 flex justify-center items-center gap-2 transition-all duration-200 rounded-md bg-brand-500 hover:opacity-80 cursor-pointer"
          >
            {isLoading && (
              <Loader className="w-4 h-4 text-white animate-spin" />
            )}
            <span className="text-sm text-white font-manrope font-semibold">
              {isLoading ? 'Loading...' : 'Save Changes'}
            </span>
          </button>
        </div>
      </form>
    </section>
  );
};
