import { SignIn } from '@components/authentication/signin';
import { SignUp } from '@components/authentication/signup';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { X } from 'lucide-react';
import React, { useState } from 'react';

interface Props {
  showModal: boolean;
  setShowModal: Function;
}

const AuthPopup: React.FC<Props> = ({ showModal, setShowModal }) => {
  const [isSignUp, setIsSignUp] = useState(false);

  const onCloseFun = () => {
    setShowModal(false);
    setIsSignUp(false);
  };

  return (
    <div className="hidden md:flex">
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none p-4 focus:outline-none font-manrope"
            onClick={onCloseFun}
          >
            <div
              className="relative mx-auto max-w-2/3"
              onClick={(e) => e.stopPropagation()}
            >
              {/*content*/}
              <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-neutral-50 outline-none focus:outline-none min-w-96">
                <div className="flex items-start justify-between px-6 pt-6 pb-2 rounded-t">
                  {isSignUp ? (
                    <div className="flex items-center gap-2">
                      <button
                        className="p-0"
                        onClick={() => setIsSignUp(false)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          fill="none"
                        >
                          <path
                            d="M26.9166 17H7.08325"
                            stroke="#28374B"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16.9999 26.9167L7.08325 17L16.9999 7.08337"
                            stroke="#28374B"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <span className="text-xl font-extrabold text-neutral-700">
                        Enter Details
                      </span>
                    </div>
                  ) : (
                    <span className="text-xl font-extrabold text-neutral-700">
                      Login
                    </span>
                  )}
                  <button className="cursor-pointer" onClick={onCloseFun}>
                    <X size={20} />
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-4 py-2 flex-auto">
                  <GoogleOAuthProvider clientId="624666456033-814o92j1gov9dq125a20b3l2c50r1k6j.apps.googleusercontent.com">
                    {isSignUp ? (
                      <SignUp type={'user'} setIsSignUp={setIsSignUp} />
                    ) : (
                      <SignIn
                        setIsSignUp={setIsSignUp}
                        setShowModel={setShowModal}
                      />
                    )}
                  </GoogleOAuthProvider>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default AuthPopup;
