import { TBrand } from "@components/types";
import { getSafeUrl, sanityIoImageLoader } from "@core/utils";
import Image from "next/image";
import Link from "next/link";
import React from "react";

type Props = {
  product: TBrand;
  loading?: "lazy" | "eager";
};

export const BrandCard: React.FC<Props> = ({ product, loading }) => {
  return (
    <div
      key={product.id}
      className="flex flex-col bg-neutral-50 border border-solid border-neutral-200 rounded-xl shrink-0 w-32 h-48"
    >
      <div className="flex h-32">
        <Link href={`/brand/${product.id}/${getSafeUrl(product.name)}`} prefetch={false}>
          <Image
            loader={sanityIoImageLoader}
            src={product?.logo || product?.image || "https://cdn.jhattse.com/public/consumer/placeholder/brand.png"}
            className="rounded-t-xl h-32 object-cover max-w-full"
            alt={product.name}
            width="160"
            height="144"
            loading={loading}
          />
        </Link>
      </div>
      <div className="flex flex-col items-center justify-center font-semibold grow gap-0.5 p-2 leading-tight text-center">
        <h3 className="md:text-sm text-xs font-manrope text-neutral-700 text-ellipsis break-words line-clamp-2 md:line-clamp-none">
          {product.name}
        </h3>
      </div>
    </div>
  );
};
