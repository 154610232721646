import { staticImageLoader } from "@core/utils";
import Image from "next/image";
import Link from "next/link";

interface Props {
    homeLink?: string
}

export const Logo = ({ homeLink }: Props) => {
    return (
        <Link href={homeLink || "/"} prefetch={false}>
            <Image
                priority={true}
                data-test="icon"
                loader={staticImageLoader}
                src="public/consumer/small-jhattse.svg"
                width="100"
                height="40"
                alt="Jhattse"
                className="rounded-full"
            />
        </Link>
    );
};
