import { TBrand } from "@components/types";
import { getSafeUrl, sanityIoImageLoader } from '@core/utils';
import Image from "next/image";
import Link from "next/link";


type Props = {
    brand: TBrand;
};


export const ExporeBrandCard = ({ brand }: Props) => {

    return (
        <div className="flex flex-col md:w-60 w-40 bg-neutral-100 border border-solid border-neutral-300 rounded-xl overflow-hidden">
            <div className="flex grow justify-center min-h-32 max-h-60 md:w-60 w-40 bg-neutral-50 overflow-hidden">
                <Link href={`/brand/${brand.id}/${getSafeUrl(brand.name)}`} prefetch={false}>
                    <Image
                        loader={sanityIoImageLoader}
                        src={
                            brand?.logo ? brand?.logo : 
                            brand?.image ? brand?.image : 
                            "https://cdn.jhattse.com/public/consumer/placeholder/brand.png"
                        }
                        alt={brand?.name}
                        width="240"
                        height="240"
                        className="w-full h-full object-cover scale-100 hover:scale-105 focus:scale-105 ease-in duration-200"
                    />
                </Link>
            </div>
            <div className="leading-tight grid gap-1 p-4">
                <div className="font-simple text-center overflow-hidden">
                    <Link href={`/brand/${brand.id}/${getSafeUrl(brand.name)}`} prefetch={false}>
                        <p className="text-base text-neutral-700 text-ellipsis font-semibold break-words line-clamp-2 md:line-clamp-none leading-tight">{brand?.name}</p>
                    </Link>
                </div>
            </div>
        </div>
    )
}