import { deleteStorePayment } from "@api/payment";
import { TPaymentMethod } from "@components/types";
import { message } from "antd";
import { Square, SquareCheckBig, Trash2 } from "lucide-react";
import { useState } from "react";


type Props = {
    payment: TPaymentMethod;
};


export const PaymentCard = ({ payment }: Props) => {
    const [isShown, setIsShown] = useState(true);

    const handleDelete = (payment: TPaymentMethod) => {
        deleteStorePayment(payment.id).then((result) => {
            setIsShown(false);
            message.success("Deleted Payment method", 2);
        }).catch(() => {
            message.error("Error while deleting payment method", 2);
        })
    }

    return (
        isShown &&
        <div className="bg-neutral-50 p-2 w-full">
            <div className="grid grid-cols-5">
                <div className="col-span-1 overflow-hidden"><span className="text-md lt-sm:text-sm text-neutral-900 font-semibold">{payment.is_upi ? "UPI" : payment.bank_name}</span></div>
                <div className="col-span-1 overflow-hidden"><span className="text-md lt-sm:text-sm text-neutral-900">{payment.is_upi ? "UPI" : payment.ifsc}</span></div>
                <div className="col-span-2 overflow-hidden px-2 text-right"><span className="text-md lt-sm:text-sm text-neutral-900">{payment.is_upi ? payment.upi : payment.account_number}</span></div>
                <div className="col-span-1 flex flex-row justify-between items-center"><span className="text-neutral-900">{payment.is_upi ? <SquareCheckBig /> : <Square />}</span><span className="text-neutral-900" onClick={() => handleDelete(payment)}><Trash2>Delete</Trash2></span></div>
            </div>
        </div>
    )
}
