import { Sidebar } from "@components/sidebar/index";
import { TStore } from "@components/types";
import { getLocation } from "@core/geolocation";
import { cartState, isLoggedIn } from "@recoil/atoms";
import { motion } from "framer-motion";
import { getDistance } from 'geolib';
import { CircleUser, House, MapPin, Phone, QrCode, ShoppingCart, Store } from 'lucide-react';
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

const QRScanner = dynamic(() => import("../qr").then((mod) => mod.QRScanner), {
    ssr: false,
});


export const Navbar: React.FC = () => {
    const [isQROpen, setQROpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isShowSidebar, setIsShowSidebar] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const [localDistance, setLocalDistance] = useState < number > ();
    const [nearestStore, setNearestStore] = useState < TStore > ()
    const isLogin = useRecoilValue(isLoggedIn);
    const router = useRouter();
    const cart = useRecoilValue(cartState);


    useEffect(() => {
        if ((nearestStore?.address !== undefined && nearestStore?.address?.latitude !== null) && router?.asPath ==="/store") {
            getLocation()?.then((userLocation) => {
                let distance = getDistance({ 'latitude': userLocation?.latitude || 27.504, 'longitude': userLocation?.longitude || 77.669 }, { 'latitude': nearestStore?.address?.latitude || 27.504, 'longitude': nearestStore?.address?.longitude || 77.669 })
                setIsShown(distance < 1000);
                setLocalDistance(distance);
            })
        }
    }, [nearestStore?.address])

    return (
        <>{
            isShown &&
            <div className="fixed -bottom-40 visible md:hidden w-full z-30 flex flex-col justify-center">
                <motion.div className="grid justify-items-stretch" drag="y"
                    dragConstraints={{
                        bottom: -40,
                        top: -200
                    }}
                    animate={isOpen ? "open" : "closed"}
                    variants={{
                        open: { x: 0, y: "-120%" },
                        closed: { y: "-20%" },
                    }}
                    transition={{ ease: "easeOut", duration: 1 }}
                    whileTap={{ scale: 0.95 }}>
                    <div className="relative justify-self-center flex justify-center w-20 h-8 -bottom-1 z-10" onClick={() => setIsOpen(!isOpen)}>
                        <div className="absolute bottom-0 justify-self-center bg-sky-700 w-20 h-10 rounded-tl-full rounded-tr-full"></div>
                        <div className="absolute bottom-0 justify-self-center bg-sky-500 w-16 h-8 rounded-tl-full rounded-tr-full"></div>
                        <div className="absolute bottom-0 justify-self-center bg-sky-300 w-12 h-6 rounded-tl-full rounded-tr-full"></div>
                        <div className="absolute bottom-0 justify-self-center bg-sky-100 w-8 h-4 rounded-tl-full rounded-tr-full"></div>
                    </div>
                    <div className="bg-neutral-800 w-full h-40 z-20 rounded-t-2xl">
                        <div className="relative w-full lt-sm:h-40 p-4">
                            <div className="w-full rounded-t-2xl">
                                <div className="grid grid-flow-col grid-cols-6">
                                    <div className="col-span-4">
                                        <h3 className="text-neutral-50 font-bold text-2xl lt-sm:text-xl line-clamp-2">{nearestStore?.name}</h3>
                                    </div>
                                    <div className="col-span-2 justify-self-end">
                                        <p className="text-neutral-50 text-2xl"><QrCode /></p>
                                    </div>
                                </div>
                                <p className="text-neutral-50">{nearestStore?.category?.name}</p>
                                <div className="flex flex-row gap-2 items-center">
                                    <p className="text-neutral-50">{nearestStore?.phone}</p>
                                    <p className="text-sky-300 text-lg font-bold"><a href={`tel:${nearestStore?.phone}`}><Phone /></a></p>
                                </div>
                                <div className="flex flex-row gap-2 items-center">
                                    <p className="text-neutral-50">{nearestStore?.address?.street_name}{", "}{nearestStore?.address?.city?.name}</p>
                                    {(localDistance != undefined) && <p className="text-sky-300 text-base lt-sm:text-sm font-bold whitespace-nowrap">{localDistance < 1000 ? localDistance.toFixed(0).toString() + " m" : (localDistance < 10000 ? (localDistance / 1000).toFixed(1).toString() + " km" : (localDistance > 100 * 1000 ? "VIEW LOCATION" : (localDistance / 1000).toFixed(0).toString() + " km"))}</p>}
                                    <p className="text-sky-300 text-lg font-bold"><a href={`http://www.google.com/maps/place/${nearestStore?.address?.latitude},${nearestStore?.address?.longitude}`} target="_blank"><MapPin /></a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        }

            <div className="z-40 bg-neutral-50 fixed h-16 p-0 flex justify-center bottom-0 left-0 right-0 md:hidden">
                <ul className="flex flex-row justify-between grow py-2 px-8">
                    <li className="nav-item text-neutral-900 px-1 flex items-center select-none">
                        <Link href="/" className="flex flex-col items-center" prefetch={false}>
                            <House size="1.8em" /><p className="text-[10px]">Home</p>
                        </Link>
                    </li>
                    <li className="nav-item text-neutral-900 px-1 flex items-center select-none">
                        <Link href="/store" className="flex flex-col items-center" prefetch={false}>
                            <Store size="1.8em" /><p className="text-[10px]">Stores</p>
                        </Link>
                    </li>
                    {/* <li className="nav-item text-neutral-900 px-1 flex items-center select-none">
                        <div onClick={() => setQROpen(!isQROpen)} className="flex flex-col items-center">
                            <ScanLine size="1.8em" /><p className="text-[10px]">QR Scan</p>
                        </div>
                    </li> */}
                    <li className="nav-item text-neutral-900 px-1 flex items-center select-none relative">
                        <Link href="/cart" className="flex flex-col items-center" prefetch={false}>
                            <ShoppingCart size="1.8em" /><p className="text-[10px]">Cart</p>
                        </Link>
                        <span
                            className={
                                cart?.size > 0
                                    ? `absolute top-0 -right-2 flex justify-center items-center text-xs text-neutral-50 bg-success-500 rounded-full h-5 w-5`
                                    : 'hidden'
                            }
                        >
                            {cart.size}
                        </span>
                    </li>
                    <li className="nav-item text-neutral-900 px-1 flex items-center select-none">
                        <span onClick={() => setIsShowSidebar(!isShowSidebar)} className="flex flex-col items-center">
                            <CircleUser size="1.8em" /><p className="text-[10px]">Account</p>
                        </span>
                    </li>
                </ul>
            </div>
            {isShowSidebar && <div className="flex flex-col items-center justify-center py-2">
                <Sidebar isShowSidebar={isShowSidebar} />
            </div>}
        </>
    );
};
