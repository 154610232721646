import { getUserLocation } from "@api/visitors";
import { TGeoLocation } from "@components/types";
import { message } from "antd";
import Cookies from "js-cookie";
let geoOptions = {
    timeout: 10 * 1000,
    enableHighAccuracy: true,
};

let defaultLocation = { "last_updated": 0, "latitude": 0, "longitude": 0 };

let setCityAndState = function (position: GeolocationPosition) {
    let location = getUserLocation(position?.coords?.longitude, position?.coords?.latitude).then(
        (currentLocation) => {
            if (currentLocation?.city?.name && currentLocation?.state?.name) {
                const myLocation = {
                    Coords: { latitude: position?.coords?.latitude || 27.504, longitude: position?.coords?.longitude || 77.669 },
                    Location: {
                        name: `${currentLocation?.city?.name}, ${currentLocation?.state?.name}`,
                        city_id: currentLocation?.city?.id,
                        state_id: currentLocation?.state?.id,
                        state_name: currentLocation?.state?.name,
                        city_name: currentLocation?.city?.name,
                    },

                };
                localStorage.setItem('location', JSON.stringify(myLocation));
                Cookies.set('location', JSON.stringify(myLocation));
                Cookies.set('longitude', JSON.stringify(position?.coords?.longitude));
                Cookies.set('latitude', JSON.stringify(position?.coords?.latitude));
            }
        }
    );
    return location
}

let geoSuccess = function (position: GeolocationPosition) {
    let updatedLocation = { "last_updated": Date.now(), "latitude": position.coords.latitude, "longitude": position.coords.longitude }
    setCityAndState(position);
    localStorage.setItem("geoposition", JSON.stringify(updatedLocation));
    return updatedLocation
};

let geoError = function (error: any) {
    if (error?.code == 1) {
        message?.error("Geolocation Permission not given")
    } else if (error.message) {
        message?.error("Geolocation couldn't be reterived")
    }
    if (localStorage.getItem("geoposition") === null) {
        localStorage.setItem("geoposition", JSON.stringify(defaultLocation));
    }
    // error.code can be:
    //   0: unknown error
    //   1: permission denied
    //   2: position unavailable (error response from location provider)
    //   3: timed out
};


export const getLocationPromise = (): Promise<GeolocationPosition> => {
    return new Promise < GeolocationPosition > ((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            position => { resolve(position) },
            error => { reject(error) }
        )
    }).catch(error => error)
}

export const getLocation = async (override: boolean = false): Promise<TGeoLocation> => {
    if (localStorage.getItem("geoposition") != undefined && !override) {
        let location = JSON.parse(localStorage.getItem("geoposition"))
        if (location?.latitude > 0) {
            return location;
        }
    }
    const location = await getLocationPromise();
    if ("code" in location) {
        geoError(location);
        return JSON.parse(localStorage.getItem("geoposition"));
    }
    return geoSuccess(location);
}
