import { getPopularProducts } from '@api/product';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { TrendingProductContainer } from "@components/container/trendingproduct";
import { TProduct } from '@components/types';
import { Dropdown, MenuProps, Space, Typography } from "antd";
import { ListFilter } from "lucide-react";
import { Suspense, useEffect, useState } from 'react';

export const TrendingProducts = () => {
    const [products, setProducts] = useState<TProduct[]>([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [isLoadMore, setIsLoadMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const pageSize = 10;


    useEffect(() => {
        const res: Promise<TProduct[]> = getPopularProducts(pageNumber, pageSize);
        res.then((result) => {
            setIsLoadMore(result.length == pageSize)
            setProducts([...products, ...result])
        })
    }, [pageNumber])

    const items: MenuProps['items'] = [
        {
            label: <button >Price: high to low</button>,
            key: '1',
        },
        {
            label: <button >Price: low to high</button>,
            key: '2',
        },
        {
            label: <button >Discount: low to high</button>,
            key: '3',
        },
        {
            label: <button >Rating: high to low</button>,
            key: '4',
        },

    ];
    return (
        <div className="bg-neutral-100 md:px-20 px-0">
            <div className="h-20 md:flex flex-col justify-center hidden">
                <Breadcrumb className="text-base text-neutral-400 font-medium font-manrope list-none text-breadcrumbs">
                    <BreadcrumbItem>
                        <BreadcrumbLink href='/'>Home</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href='/trending' className="text-neutral-800 font-semibold">Trending</BreadcrumbLink>
                        <ol className="p-0 list-none"></ol>
                    </BreadcrumbItem>
                </Breadcrumb>
            </div>
            <div className="flex justify-between item-center py-2">
                <div>
                    <h1 className="font-bold text-2xl text-neutral-900">Trending Products</h1>
                </div>
                <div className="flex items-center">
                    <Dropdown menu={{ items, selectable: true, defaultSelectedKeys: ['1'] }} trigger={['click']} >
                        <Typography.Link>
                            <Space>
                                <ListFilter className="text-neutral-900 font-bold text-lg" />
                            </Space>
                        </Typography.Link>
                    </Dropdown>
                </div>
            </div>
            <Suspense>
                <TrendingProductContainer products={products}
                    isLoading={isLoading}
                    isLoadMore={isLoadMore}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                />
            </Suspense>
        </div>
    )
}
