import { TProductCategory } from "@components/types";
import { getSafeUrl, sanityIoImageLoader } from "@core/utils";
import Image from "next/image";
import Link from "next/link";
import React from "react";

type Props = {
    product: TProductCategory;
    loading?: "lazy" | "eager"
};


export const SmallCategoryCard: React.FC<Props> = ({ product, loading }) => {
    return (
        <div className="relative flex-shrink-0">
            <div className="text-center flex flex-col justify-end gap-1 w-20">
                <div className="flex justify-center rounded-full bg-brand-100">
                    <Link href={`/category/${product.id}/${getSafeUrl(product.name)}`} className="flex items-center h-20" prefetch={false}>
                        <Image
                            loader={sanityIoImageLoader}
                            src={product.image || "https://cdn.jhattse.com/public/consumer/placeholder/product_circular.png"}
                            alt={product.name}
                            width="128"
                            height="128"
                            className="rounded-full h-18 w-18 aspect-square"
                            loading={loading}
                        />
                    </Link>
                </div>
                <Link href={`/category/${product.id}/${getSafeUrl(product.name)}`} prefetch={false}>
                    <div className="overflow-hidden line-clamp-2 md:line-clamp-none">
                        <p className="text-neutral-600 font-semibold text-xs text-ellipsis text-center leading-tight">{product.name}</p>
                    </div>
                </Link>
            </div>
        </div>
    )
}