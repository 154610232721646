import { CartItemCard } from '@components/cards/cartitem';
import { InstructionForm } from '@components/instruction';
import { TCartItem } from '@components/types';
import { getFirst, getLength, getSafeUrl } from '@core/utils';
import { MapPin } from 'lucide-react';
import Link from 'next/link';
import { useState } from 'react';

interface Props {
  cartItems: TCartItem[];
}

export const StoreCartContainer = ({ cartItems }: Props) => {
  const [instruction, setInstruction] = useState(false);
  const firstCartItem = getFirst(cartItems);
  return getLength(cartItems) > 0 ? (
    <div className="">
      {!cartItems[0].deliverable && (
        <div className="">
          <div className="flex justify-between bg-teal-500 px-2 py-1">
            <span className="font-semibold text-base text-neutral-50">
              Pickup From
            </span>
            <div className="flex font-semibold text-base text-neutral-50">
              <span>
                {firstCartItem?.inventory?.store?.address?.street_name}
              </span>
              <p className="text-neutral-50 text-lg font-bold mt-1">
                <a
                  href={`http://www.google.com/maps/place/${firstCartItem?.inventory?.store?.address?.latitude},${firstCartItem?.inventory?.store?.address?.longitude}`}
                  target="_blank"
                >
                  <MapPin />
                </a>
              </p>
            </div>
          </div>
          <div className="flex justify-start items-center bg-neutral-50  px-2 py-1 gap-2">
            <span className="font-semibold text-sm text-slate-700">
              Sold by
            </span>
            <Link
              href={`/store/${firstCartItem?.inventory?.store?.id}/${getSafeUrl(
                getFirst(cartItems)?.inventory?.store?.name
              )}`}
            >
              <span className="font-semibold text-sm text-sky-400">
                {firstCartItem?.inventory?.store?.name}
              </span>
            </Link>
          </div>
        </div>
      )}
      <div className="grid md:grid-cols-2 w-full gap-4 ">
        {cartItems?.map((cartItem: TCartItem) => (
          <div
            key={cartItem?.product?.id}
            className="shadow-md bg-neutral-50  rounded-lg overflow-hidden"
          >
            <CartItemCard cartItem={cartItem} showStore={false} />
          </div>
        ))}
      </div>
      <div className="flex w-full justify-end font-semibold py-1 ">
        <button
          onClick={() => {
            setInstruction(!instruction);
          }}
          className="bg-neutral-50"
        >
          {instruction == true ? 'Cancel' : 'Add instructions'}
        </button>
      </div>
      {instruction == true ? (
        <div className="bg-neutral-50">
          <InstructionForm />
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
};
