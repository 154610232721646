import { Cards } from "@components/cards";
import { TAsset, TAssetGroup, TAssetService, TBrand, TProduct, TProductCategory } from "@components/types";
import Link from "next/link";

type Props = {
    title?: string
    intent?: string
    products: (TProduct | TProductCategory | TBrand | TAsset | TAssetGroup | TAssetService)[]
    style?: string
    headStyle?: string
    cardWidth?: string
    viewUrl?: string
    element?: React.ElementType
    isCarousel?: boolean
    loading?: "lazy" | "eager"
    isAvailable?: boolean
    isMarquee?: boolean
};

export const Section: React.FC<Props> = ({ element, intent, title, products, cardWidth, style, headStyle, viewUrl, isCarousel, loading, isAvailable,isMarquee }) => {
    let finalStyle = style == undefined || style == "" ? "pb-4 bg-ternary" : style;
    return (
        products?.length > 0 &&
        // <div className={`section select-none ${finalStyle}`}>
        <div className={`section select-none ${finalStyle}`}>
            {title && <div className="flex flex-row justify-between pb-2">
                <div className={headStyle === null || headStyle === undefined ? " font-manrope text-xl py-1 px-4 font-semibold" : headStyle}>{title}</div>
                {viewUrl && <div className="flex font-manrope items-center text-brand_cerulean text-xs py-1 pl-4 font-bold"><Link href={viewUrl} prefetch={false}>VIEW ALL</Link>
                </div>}
            </div>}
            <Cards isCarousel={isCarousel} products={products} element={element} intent={intent} cardWidth={cardWidth} loading={loading} isAvailable={isAvailable} isMarquee={isMarquee} />
        </div>
    )
}